const modal = document.querySelector('#modal');
const videoContainer = document.querySelector('.video-container');
const closeVidBtn = document.querySelector('.close-video-btn');

const openBtnKlips = document.querySelector('.open-btn-klips');
const openBtnKicks = document.querySelector('.open-btn-kicks');
const openBtnNews = document.querySelector('.open-btn-news');

openBtnKlips.addEventListener('click', () => {
    modal.classList.remove('no-show-modal');

    let element = `
        <iframe
            class="video"
            src="https://www.youtube.com/embed/f2Px1h6T-jU?&autoplay=0&fs=0&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
    `;
    videoContainer.innerHTML += element;
});

openBtnKicks.addEventListener('click', () => {
    modal.classList.remove('no-show-modal');

    let element = `
        <iframe
            class="video"
            src="https://www.youtube.com/embed/UROXUejNtxM?&autoplay=0&fs=0&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
    `;
    videoContainer.innerHTML += element;
});

openBtnNews.addEventListener('click', () => {
    modal.classList.remove('no-show-modal');

    let element = `
        <iframe
            class="video"
            src="https://www.youtube.com/embed/xzyOKbIwkxg?&autoplay=0&fs=0&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
    `;
    videoContainer.innerHTML += element;
});

closeVidBtn.addEventListener('click', () => {
    videoContainer.innerHTML = '';
    modal.classList.add('no-show-modal');
});
